import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import emailjs from '@emailjs/browser';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Box, Typography, CircularProgress, Snackbar, Alert, TextField, Button, useMediaQuery } from '@mui/material';

import Linkify from 'linkify-react';
import { addEventV2, auth, updateEvent, updateSubmittedPlan, fetchEvents } from '../services/firebase';
import Page from '../layouts/Page';
import DateRowVote from '../components/DateRowVote/DateRowVote';
import PlanningFlow from '../components/PlanningFlow/PlanningFlow';
import useGastroStore from '../stores/gastroStore';

const Planning = function Planning() {
    const [user, loading] = useAuthState(auth);
    const isDesktop = useMediaQuery('(min-width:960px)');
    const [eventLoading, setEventLoading] = useState(false);
    const [eventData, setEventData] = useState({});

    const [gastroData, setGastroData] = useState({});
    const { allGastros, LiUid, LiUserName, SgEmail, SgName, SgUid, SgSubmittedPlan } = gastroData;
    const { setSgSubmittedPlan } = useGastroStore();

    useEffect(() => {
        // check sessionStorage exists, if it doesnt redirect to main page
        if (!sessionStorage.getItem('gastro-storage')) {
            window.location.href = '/';
        }
        setGastroData(JSON.parse(sessionStorage.getItem('gastro-storage')).state);
    }, [sessionStorage]);

    const [state, setState] = useState({
        venueConfirmed: false,
        notes: '',
        email: user.email,
        success: false,
        selectedGastroEmail: SgEmail || '',
        fetchLoading: false,
        userName: SgName || '',
        dateVotes: [],
        dateVoteCount: 0,
        uid: SgUid || '',
        profileBadge: '',
        hasSubmittedPlan: SgSubmittedPlan || false,
        loggedInGastroUID: LiUid || '',
        loggedInGastroName: LiUserName || '',
        allGastroData: allGastros || [],
    });
    const [submitted, setSubmitted] = useState(false);

    const {
        email,
        selectedGastroEmail,
        success,
        fetchLoading,
        userName,
        uid,
        hasSubmittedPlan,
        loggedInGastroUID,
        loggedInGastroName,
        allGastroData,
    } = state;

    useEffect(() => {
        if (user) {
            setEventLoading(true);
            fetchEvents()
                .then((data) => {
                    setEventData(data[0]);
                    sessionStorage.setItem('EventData', JSON.stringify(data[0]));
                    setEventLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [user]);

    const dataLoading = loading === 'true' || fetchLoading || eventLoading;
    const venue = _.get(eventData, 'venueChoice', '');
    const dates = _.get(eventData, 'submittedDates', []);
    const venueNotes = _.get(eventData, 'notes', '');
    const venueMenuLink = _.get(eventData, 'menuLink', '');
    const eventId = _.get(eventData, 'eventId', '');
    const test = _.get(eventData, 'test', false);
    const API_KEY = process.env.REACT_APP_EMAILJS_USER_ID;

    const sendEmail = (values) => {
        const datesSplit = values.submittedDates.toString().split(',');
        const datesWithSpaces = datesSplit.map((date) => `${date} `);
        const datesWithSpacesString = datesWithSpaces.toString();

        const gastroEmails = allGastroData.map((gastro) => gastro.email);
        const emergencyFilter = gastroEmails.filter((gastro) => gastro.email === 'rtarplee@outlook.com');
        const gastroEmailsString = emergencyFilter.toString();

        const templateParams = {
            cc_list: gastroEmailsString,
            to_name: 'Gastro',
            active_gastro: userName,
            from_name: 'Gastronaut HQ',
            venue_selected: values.venueChoice.toString(),
            dates_selected: datesWithSpacesString,
            notes: values.notes.toString(),
        };

        emailjs.send('service_o16cfps', 'template_pju9wwq', templateParams, API_KEY).then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                console.log('FAILED...', error);
            },
        );
    };

    const sendVoteEmail = () => {
        const templateParams = {
            to_name: userName,
            active_gastro: userName,
            active_gastro_email: selectedGastroEmail.toString(),
            voter_name: loggedInGastroName,
            from_name: 'Gastronaut HQ',
        };
        emailjs.send('service_o16cfps', 'template_7bofxue', templateParams, API_KEY).then(
            (response) => {
                console.log('SUCCESS!', response.status, response.text);
            },
            (error) => {
                console.log('FAILED...', error);
            },
        );
    };

    const submitPlan = (values) => {
        const { venueChoice, submittedDates, notes, menuLink } = values;
        const noCanDo = 'None of these work';
        submittedDates.push(noCanDo);
        const preFlightValues = {
            venueChoice,
            submittedDates,
            notes,
            menuLink,
            uid,
        };
        try {
            addEventV2(email, preFlightValues)
                .then(() => {
                    setSgSubmittedPlan(true);
                })
                .then(() => {
                    updateSubmittedPlan(uid, true);
                })
                .then(() => {
                    sendEmail(preFlightValues);
                    setState((updatedState) => ({
                        ...updatedState,
                        venueConfirmed: false,
                        success: true,
                    }));
                    // navigate to main page
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1500);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const mergedVotes = [];

    const onDeleteVote = (v) => {
        const found = mergedVotes.find((element) => v[0].date === element.date);
        mergedVotes.splice(mergedVotes.indexOf(found), 1);
    };

    const voteMade = (returned, existing) => {
        if (!_.isEmpty(returned)) {
            mergedVotes.push(...existing, ...returned);

            // remove duplicates
            const uniqueVotes = _.uniqBy(mergedVotes, 'voteId');
            mergedVotes.length = 0;
            mergedVotes.push(...uniqueVotes);
        }
    };

    const isActiveGastro = selectedGastroEmail === user.email;

    const checkExistingVotes = useCallback(() => {
        if ('votes' in eventData) {
            const found = eventData.votes.find((element) => element.uid === loggedInGastroUID);
            if (found) {
                return true;
            }
            return false;
        }
        return false;
    }, [eventData, loggedInGastroUID]);

    const linkProps = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };

    return (
        <Page>
            <Box m={{ xs: 1, sm: 4 }} p={4} backgroundColor="#ffbf46">
                <Typography
                    variant="h2"
                    letterSpacing="1px"
                    fontWeight={400}
                    m="0 auto"
                    sx={{
                        fontSize: { xs: '24px', sm: '40px' },
                    }}
                >
                    Planning
                </Typography>
            </Box>
            {dataLoading ? (
                <CircularProgress />
            ) : (
                <Box
                    p={{ xs: 1, sm: 4 }}
                    m={{ xs: 1, sm: 4 }}
                    sx={{
                        backgroundColor: 'rgba(255,255,255,1)',
                    }}
                >
                    <Box>
                        {success && (
                            <Snackbar open={success} autoHideDuration={300}>
                                <Alert
                                    severity="success"
                                    onClose={() =>
                                        setState((updatedState) => ({
                                            ...updatedState,
                                            success: false,
                                        }))
                                    }
                                >
                                    {' '}
                                    Successfully saved!
                                </Alert>
                            </Snackbar>
                        )}
                        <Typography variant="body1">
                            This page is designed to help you organise your Gastronauts meal.
                        </Typography>
                        <Typography variant="body1">
                            You can vote on existing plans, or submit your own if you have the baton.
                        </Typography>
                        <br />

                        {isActiveGastro && !hasSubmittedPlan ? (
                            <PlanningFlow submitPlan={(values) => submitPlan(values)} />
                        ) : (
                            <Box>
                                <Typography variant="body1">
                                    {' '}
                                    It&apos;s currently {userName}
                                    &apos;s turn to organise the meal.
                                </Typography>
                                <Typography variant="body1">He has suggested the following:</Typography>
                                <>
                                    <Box my={4} width={isDesktop ? '50%' : '358px'}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Venue"
                                            name="Venue Choice"
                                            value={venue}
                                            disabled
                                        />
                                    </Box>
                                    {venueNotes && (
                                        <Box my={4} width={isDesktop ? '50%' : '358px'}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Notes"
                                                name="Notes"
                                                value={venueNotes}
                                                type="textarea"
                                                disabled
                                                multiline
                                            />
                                        </Box>
                                    )}
                                    {venueMenuLink && (
                                        <Box my={4} width={isDesktop ? '50%' : '358px'}>
                                            <Typography variant="body1">Menu:</Typography>
                                            <Typography variant="body1" color="#000">
                                                <Linkify options={{ attributes: linkProps }}>{venueMenuLink}</Linkify>
                                            </Typography>
                                        </Box>
                                    )}
                                    <Typography>Vote for dates below</Typography>
                                    <Box display="flex" flexDirection="column" justifyContent="space-between">
                                        {dates.map((value) => (
                                            <DateRowVote
                                                key={value}
                                                value={value}
                                                existingVotes={eventData.votes}
                                                venue={venue}
                                                onDelete={(d) => onDeleteVote(d)}
                                                onChange={(returned, existing) => voteMade(returned, existing)}
                                                disabled={
                                                    test ||
                                                    selectedGastroEmail === user.email ||
                                                    submitted ||
                                                    checkExistingVotes()
                                                }
                                            />
                                        ))}
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={
                                                test ||
                                                submitted ||
                                                (isActiveGastro && hasSubmittedPlan) ||
                                                checkExistingVotes()
                                            }
                                            sx={{ mt: 3, mb: 2, width: { xs: '100%', sm: '120px' } }}
                                            onClick={() => {
                                                updateEvent(eventId, mergedVotes);
                                                sendVoteEmail();
                                                setSubmitted(true);
                                                sessionStorage.setItem(
                                                    'localVotesSubmitted',
                                                    JSON.stringify(mergedVotes),
                                                );
                                            }}
                                        >
                                            Submit
                                        </Button>
                                        <Typography>
                                            Careful now, Once you submit, your votes are in and you are bound by the
                                            code of gastro to honour your agreement.
                                        </Typography>
                                        <Typography fontSize="10px">
                                            (and I can&apos;t be arsed to write the code to allow you to change your
                                            mind)
                                        </Typography>
                                        <Box
                                            component="img"
                                            src="/images/memes/careful.png"
                                            alt="careful now"
                                            width="150px"
                                            height="150px"
                                            mt={2}
                                        />
                                    </Box>
                                </>
                            </Box>
                        )}
                        {_.isEmpty(eventData) && (
                            <>
                                <Typography variant="body1">Bit Eager aren&apos;t we?</Typography>
                                <Typography variant="body1">
                                    {userName} has not yet submitted a plan. Please check back later.
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </Page>
    );
};

export default Planning;
